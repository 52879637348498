import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { get } from '../../lib/nodash';
import ImgixImage from '../ImgixImage';
import useRichText from '../useRichText';
import useMobile from '../useMobile';

const DynamicHeroCollage = ({ blok }) => {
  const {
    title,
    body,
    images,
    mobileImage,
    contentAnchor = 'center',
    mobileContainImage = false,
    ...rest
  } = blok;
  const isMobile = useMobile();
  const richText = useRichText(body, {}, rest);

  const image1 = images[0];
  const image2 = images[1];
  const image3 = images[2];

  return (
    <Box
      {...storyblokEditable(blok)}
      height={isMobile ? 'auto' : '100vh'}
      {...rest}
    >
      <Box fill align="center" justify="center">
        <Stack
          interactiveChild="last"
          fill
          alignSelf="center"
          anchor={contentAnchor}
        >
          {isMobile && mobileImage ? (
            <Box>
              <ImgixImage
                src={get('filename', mobileImage)}
                fit={mobileContainImage ? 'contain' : 'cover'}
                height="100%"
                width="100%"
                lazy={true}
                options={{ q: 80, maxw: 1500, auto: ['compress', 'format'] }}
                alt={title}
                a11yTitle={`Photo of ${get('alt', mobileImage)}`}
              />
            </Box>
          ) : (
            <Box fill>
              {image1 && (
                <ImgixImage
                  src={get('image.filename', image1)}
                  fit={mobileContainImage ? 'contain' : 'cover'}
                  height="100%"
                  width="43%"
                  lazy={true}
                  srcSetOptions={{ auto: ['compress', 'format'] }}
                  srcSetRanges={{ minWidth: 200, maxWidth: 2000 }}
                  sizes={'20vw, 50vw'}
                  alt={title}
                  a11yTitle={`Photo of ${get('image.alt', image1)}`}
                />
              )}
              {image2 && (
                <ImgixImage
                  src={get('image.filename', image2)}
                  fit={mobileContainImage ? 'contain' : 'cover'}
                  height="44%"
                  width="23%"
                  style={{ position: 'absolute', top: '3%', right: '6%' }}
                  lazy={true}
                  srcSetOptions={{ auto: ['compress', 'format'] }}
                  srcSetRanges={{ minWidth: 200, maxWidth: 2000 }}
                  sizes={'20vw, 50vw'}
                  alt={title}
                  a11yTitle={`Photo of ${get('image.alt', image2)}`}
                />
              )}
              {image3 && (
                <ImgixImage
                  src={get('image.filename', image3)}
                  fit={mobileContainImage ? 'contain' : 'cover'}
                  height="48.5%"
                  width="42%"
                  style={{ position: 'absolute', right: '0', bottom: '0' }}
                  lazy={true}
                  srcSetOptions={{ auto: ['compress', 'format'] }}
                  srcSetRanges={{ minWidth: 200, maxWidth: 2000 }}
                  sizes={'20vw, 50vw'}
                  alt={title}
                  a11yTitle={`Photo of ${get('image.alt', image3)}`}
                />
              )}
            </Box>
          )}

          <Box
            justify="center"
            align="center"
            pad="small"
            height="100%"
            alignSelf="center"
            width={isMobile ? '77vw' : '100%'}
          >
            <Box fill>{richText}</Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

DynamicHeroCollage.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    height: PropTypes.string,
    images: PropTypes.array,
    mobileContainImage: PropTypes.bool,
    mobileImage: PropTypes.object,
    contentAnchor: PropTypes.oneOf([
      'center',
      'top',
      'top-right',
      'top-left',
      'right',
      'left',
      'bottom-right',
      'bottom-left',
    ]),
  }),
};

export default memo(DynamicHeroCollage);
